/* agradecimento.css */
.div-princ {
    background: linear-gradient(120deg, rgba(0, 153, 204, 0.8) 0%, rgba(48, 207, 208, 0.8) 100%), url('imagem.webp');
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Poppins', sans-serif;
  }
  
  .agradecimento-form {
    width: 20rem;
    max-width: 90%;
    height: auto;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    padding: 1rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .agradecimento-form > h1 {
    color: #333;
    margin-bottom: 1rem;
  }
  
  .agradecimento-form > p {
    color: #333;
    font-size: 0.9rem;
    margin-bottom: 0.5rem;
  }
  
  .agradecimento-form > a {
    color: #4a90e2;
    text-decoration: underline;
    font-size: 0.9rem;
    margin-top: 0.5rem;
  }
  
  .agradecimento-form > a:hover {
    color: #357ebd;
  }
  
  /* You can customize the styles further as needed */
  