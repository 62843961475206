
  @import url('https://fonts.googleapis.com/css2?family=Lato&family=Poppins&display=swap');

  body, html {
    height: 100%;
    margin: 0;

  }
  
  .div-princ {
    background: linear-gradient(120deg, rgba(0, 153, 204, 0.8) 0%, rgba(48, 207, 208, 0.8) 100%),  url('imagem.webp');
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Poppins', sans-serif;
  }
  
  .form {
    width: 20rem;
    max-width: 90%; /* Make it responsive */
    height: auto; /* Set to auto to allow content to determine height */
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    padding: 1rem; /* Add padding for better spacing */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Add a subtle shadow for depth */
  }
  
  .inputs {
    width: 100%;
    max-width: 15rem; /* Adjust the maximum width of the input container */
    display: flex;
    flex-direction: column;
    margin-top: 1rem; /* Add margin for better separation from the form */
  }
  
  .inputs > label {
    margin-bottom: 0.5rem; /* Add margin for better separation between labels */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    font-size: 0.9rem; /* Adjust font size for better readability */
    color: #333; /* Add color to text for better contrast */
  }
  
  .inputs > label > input {
    border: 1px solid #ccc;
    width: 95%;
    outline: none;
    background: #f8f8f8; /* Add a light background color for better visibility */
    padding: 0.5rem;
    margin: 0;
    border-radius: 5px; /* Add a slight border radius for a modern look */
    font-size: 0.9rem; /* Adjust font size for better readability */
    transition: border-color 0.3s ease; /* Add a smooth transition for better user experience */
    
  
    /* Additional styles for focus state */
    &:focus {
      border-color: #4a90e2; /* Change border color on focus for better feedback */
    }
  }
  .button {
    background-color: #4a90e2; /* Cor de fundo */
    color: #fff; /* Cor do texto */
    padding: 0.5rem 1rem; /* Espaçamento interno */
    border: none; /* Remover a borda */
    border-radius: 5px; /* Borda arredondada */
    cursor: pointer; /* Cursor de mão ao passar o mouse */
    font-size: 1rem; /* Tamanho da fonte */
    transition: background-color 0.3s ease; /* Transição suave da cor de fundo */
  
    /* Estilo de foco */
    &:focus {
      outline: none; /* Remover contorno de foco padrão */
      box-shadow: 0 0 5px rgba(74, 144, 226, 0.7); /* Adicionar uma sombra suave ao focar */
    }
  
    /* Estilo de hover */
    &:hover {
      background-color: #357ebd; /* Cor de fundo ao passar o mouse */
    }
  }
  .button:disabled {
    background-color: #ffffff; /* Cor de fundo */
    color: #4a90e2; /* Cor do texto */
    padding: 0.5rem 1rem; /* Espaçamento interno */
    border: none; /* Remover a borda */
    border-radius: 5px; /* Borda arredondada */
    cursor: pointer; /* Cursor de mão ao passar o mouse */
    font-size: 1rem; /* Tamanho da fonte */
    transition: background-color 0.3s ease; /* Transição suave da cor de fundo */
  
    /* Estilo de foco */
    &:focus {
      outline: none; /* Remover contorno de foco padrão */
      box-shadow: 0 0 5px rgba(74, 144, 226, 0.7); /* Adicionar uma sombra suave ao focar */
    }
  
    /* Estilo de hover */
    &:hover {
      background-color: #357ebd; /* Cor de fundo ao passar o mouse */
    }
  }
  /* Media query for responsiveness */
  @media (max-width: 768px) {
    .form {
      width: 90%;
    }
  }
  